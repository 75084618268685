<template>
    <div>
        <AppOrderList :orders="orders"/>
    </div>
</template>

<script>
    import AppOrderList from '@/components/app/history/OrderList'

    import {
        cloneDeep as _cloneDeep,
        forEach as _forEach,
    } from 'lodash'
    import moment from 'moment'

    import { mapGetters, mapMutations, mapActions } from 'vuex'

    export default {
        name: 'HistoryIndex',

        components: {
            AppOrderList,
        },

        data() {
            return {}
        },

        computed: {
            ...mapGetters({
                getOrders: 'order/getOrders',
            }),

            appBarData() {
                return {
                    dark: true,
                    navigationIcon: {
                        type: 'nav',
                    },
                    title: 'История заказов',
                }
            },

            orders() {
                let orders = _cloneDeep(this.getOrders)

                _forEach(orders, Order => {
                    Order.createdAtFormatted = moment(Order.createdAt).locale('ru').format('D MMMM Y')
                })

                return orders
            },
        },

        mounted() {
            this.setAppBarData()
            this.fetchOrders()
        },

        methods: {
            ...mapMutations({
                setAppBar: 'ui/setAppBar',
            }),

            ...mapActions({
                fetchOrders: 'order/fetchOrders',
            }),

            setAppBarData() {
                this.setAppBar(this.appBarData)
            },
        },
    }
</script>
